<template>
  <div class="layout">
    <Sider :style="{
      position: 'fixed',
      height: '93.5vh',
      left: 0,
      overflow: 'auto',
    }">
      <Menu active-name="1-1" theme="dark" width="auto" :open-names="['1']">
        <Submenu v-for="(item, index) in selList" :key="index" :name="item.node_val">
          <template slot="title">
            <!-- <Icon type="ios-navigate"></Icon> -->
            <img class="img1" :src="imgU + item.img[1]" />
            {{ item.node_name }}
          </template>
          <Menu-item v-for="(items, index) in item.son" :name="items.node_val" :key="index"
            @click.native="skip(items.node_url, items.node_name)"><b>{{ items.node_name }}</b></Menu-item>
        </Submenu>
        <!-- 	<Submenu name="1">
				<template slot="title">
					<Icon type="ios-navigate"></Icon>
					Item 1
				</template>
				<Menu-item name="1-1" @click.native="skip('index')"><b >首页</b></Menu-item>
				<Menu-item name="1-2" @click.native="skip('maintain')"><b >成员列表</b></Menu-item>
				<Menu-item name="1-3">Option 3</Menu-item>
			</Submenu> -->
        <!-- <Submenu name="4">
				<template slot="title">
					<Icon type="ios-analytics"></Icon>
					商品
				</template>
				<Menu-item name="4-1" @click.native="skip('commodityList')"><b>商品列表</b></Menu-item>
				<Menu-item name="4-2" @click.native="skip('addCommodity')"><b >	添加商品</b></Menu-item>
				<Menu-item name="4-3" @click.native="skip('commodityAudit')"><b >商品审核</b></Menu-item>
				<Menu-item name="4-4" @click.native="skip('commodityRecycle')"><b >商品回收站</b></Menu-item>
				<Menu-item name="4-6" @click.native="skip('commodityComment')"><b >商品评价</b></Menu-item>
				<Menu-item name="4-7" @click.native="skip('commodityClassify')"><b >商品分类</b></Menu-item>
				<Menu-item name="4-8" @click.native="skip('commodityType')"><b >商品类型</b></Menu-item>
				<Menu-item name="4-9" @click.native="skip('commoditySign')"><b >商品标记</b></Menu-item>
				<Menu-item name="4-10" @click.native="skip('commodityMeasure')"><b >商品计量单位</b></Menu-item>
				
			</Submenu> -->
        <!-- 	<Submenu name="2">
				<template slot="title">
					<Icon type="ios-keypad"></Icon>
					店铺
				</template>
				<Menu-item name="2-1" @click.native="skip('addList')"><b >店铺列表</b></Menu-item>
				<Menu-item name="2-2" @click.native="skip('addshop')"><b>添加店铺</b></Menu-item>
				<Menu-item name="2-3" @click.native="skip('industry')"><b>行业管理</b></Menu-item>
				<Menu-item name="2-4" @click.native="skip('categories')"><b>主营类目</b></Menu-item>
				<Menu-item name="2-5" @click.native="skip('brand')"><b>品牌管理</b></Menu-item>
			</Submenu>
			<Submenu name="5">
				<template slot="title">
					<Icon type="ios-analytics"></Icon>
					订单
				</template>
				<Menu-item name="5-1" @click.native="skip('orderList')"><b>订单列表</b></Menu-item>
				<Menu-item name="5-2" @click.native="skip('affirmGoods')"><b>确认收货</b></Menu-item>
				<Menu-item name="5-3" @click.native="skip('arriveRemind')"><b>到货提醒</b></Menu-item>
				<Menu-item name="5-4" @click.native="skip('orderSetting')"><b>订单设置</b></Menu-item>
				<Menu-item name="5-5" @click.native="skip('salesReturn')"><b>退货申请处理</b></Menu-item>
				<Menu-item name="5-6" @click.native="skip('reimburse')"><b>退款申请处理</b></Menu-item>
				<Menu-item name="5-7" @click.native="skip('causeReturn')"><b>退货原因设置</b></Menu-item>
				<Menu-item name="5-8" @click.native="skip('expressWaybill')"><b>快递单模板</b></Menu-item>
				<Menu-item name="5-9" @click.native="skip('definedPrint')"><b>自定义打印</b></Menu-item>
				<Menu-item name="5-10" @click.native="skip('deliverPoint')"><b>发货点信息管理</b></Menu-item>
			</Submenu>
			<Submenu name="6">
				<template slot="title">
					<Icon type="ios-analytics"></Icon>
					会员
				</template>
				<Menu-item name="6-1" @click.native="skip('member')"><b>会员列表</b></Menu-item>
				<!-- <Menu-item name="6-2"><b @click="skip('affirmGoods')">购买力筛选</b></Menu-item> -->
        <!-- <Menu-item name="6-3" @click.native="skip('Manlabel')"><b>标签管理</b></Menu-item>
				<Menu-item name="6-4" @click.native="skip('manrank')"><b>会员等级设置</b></Menu-item>
				<Menu-item name="6-5" @click.native="skip('salesReturn')"><b>成长值及积分查询</b></Menu-item>
				<Menu-item name="6-6" @click.native="skip('reimburse')"><b >任务奖励设置</b></Menu-item>
				<Menu-item name="6-7" @click.native="skip('causeReturn')"><b>更多规则设置</b></Menu-item>
			</Submenu>
				<Submenu name="8">
				<template slot="title">
					<Icon type="ios-analytics"></Icon>
					库存
				</template>
				<Menu-item name="8-1" @click.native="skip('shopset')"><b>商品入库</b></Menu-item>
				<Menu-item name="8-2" @click.native="skip('shopget')"><b>商品出库</b></Menu-item>
			
			</Submenu>
			<Submenu name="9">
				<template slot="title">
					<Icon type="ios-analytics"></Icon>
					分销
				</template>
				<Menu-item name="9-1" @click.native="skip('setretail')"><b>分销基础设置</b></Menu-item>
				<Menu-item name="9-2" @click.native="skip('retailist')"><b>分销商列表</b></Menu-item>
				<Menu-item name="9-3" @click.native="skip('retailrank')"><b>分销等级</b></Menu-item>
			
			</Submenu>
			<Submenu name="3">
				<template slot="title">
					<Icon type="ios-analytics"></Icon>
					权限
				</template>
				<Menu-item name="3-1" @click.native="skip('maintain')"><b>管理员维护</b></Menu-item>
				<Menu-item name="3-2" @click.native="skip('role')"><b>角色设置</b></Menu-item>
				<Menu-item name="3-3" @click.native="skip('addUser')"><b>添加管理员</b></Menu-item>
			</Submenu>
			 <Submenu name="7">
			          <template slot="title">
			            <Icon type="ios-analytics"></Icon>
			            促销
			          </template>
			          <Menu-item name="7-1" @click.native="skip('activityList')"
			            ><b >秒杀活动列表</b></Menu-item
			          >
			          <Menu-item name="7-2" @click.native="skip('addCouponInfo')"
			            ><b>添加优惠券</b></Menu-item
			          >
			          <Menu-item name="7-3" @click.native="skip('couponList')"
			            ><b>优惠券列表</b></Menu-item
			          >
			        </Submenu>
			        <Submenu name="10">
			          <template slot="title">
			            <Icon type="ios-analytics"></Icon>
			            运营
			          </template>
			          <Menu-item name="10-1" @click.native="skip('advertisingList')"
			            ><b>广告列表</b></Menu-item
			          >
			          <Menu-item name="10-2" @click.native="skip('addAdvertising')"
			            ><b>添加广告</b></Menu-item
			          >
			        </Submenu>
			
			        <Submenu name="11">
			          <template slot="title">
			            <Icon type="ios-analytics"></Icon>
			            设置
			          </template>
			          <Menu-item name="11-1" @click.native="skip('latformInfo')"
			            ><b>平台信息</b></Menu-item
			          >
			          <Menu-item name="11-2" @click.native="skip('freight')"
			            ><b>运费模板</b></Menu-item
			          >
			          <Menu-item name="11-3" @click.native="skip('logistics')"
			            ><b>物流公司</b></Menu-item
			          >
			        </Submenu> -->
      </Menu>
    </Sider>
    <Header :style="{
      background: '#fff',
      boxShadow: '0 2px 3px 2px rgba(0,0,0,.1)',
      padding: '0 0',
    }">
      <div class="menu">
        <div class="titleUser" ref="titleUser">
          <div class="login">
            <img src="../../../public/xmwp.png" />
          </div>
          <div class="titleO">
            <div class="title_left title">
              <div class="loginText">行长甄选后台管理系统（南宁分行）</div>
            </div>
            <div class="title_right title">
              <div class="user item">
                <div class="" style="line-height: 88px">
                  <!-- <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar> -->
                </div>
              </div>
              <div class="admin item">{{ login_name }}</div>
              <!-- 首页 -->
              <div class="index item" title="首页" @click="skip('index')">
                <i class="el-icon-s-home"></i>
              </div>
              <!-- 	菜单
							<el-dropdown>
								<div class="list item" title="菜单导航"><i class="el-icon-s-operation"></i></div>
								<el-dropdown-menu slot="dropdown">
									<div class="menus">
										<div class="menuCon">
											<div class="menuTit">
												<div class="titleUse">常用菜单</div>
												<div class="management">菜单管理</div>
											</div>
											<div class="menuNei">
												<div class="menuList">
													列表
												</div>
												<div class="menuList">
													列表
												</div>
												<div class="menuList">
													列表
												</div>
												<div class="menuList">
													列表
												</div>
												<div class="menuList">
													列表
												</div>
												<div class="menuList">
													列表
												</div>
												<div class="menuList">
													列表
												</div>
												<div class="menuList">
													列表
												</div>
												<div class="menuList">
													列表
												</div>
											</div>
										</div>
									</div>
								</el-dropdown-menu>
							</el-dropdown> -->
              <!-- 订单 -->
              <!-- 	<el-dropdown>
								<div class="message item" title="提示消息"><i class="el-icon-message-solid"></i>
									<div class="quan">1</div>
								</div>
								<el-dropdown-menu slot="dropdown">
									<div class="messages">
										<div class="messageCla">
											<div class="mesClaTit">
												订单提示
											</div>
											<div class="mesList">
												<div class="conNam">123</div>
												<div class="num">(10)</div>
											</div>
											<div class="mesList">
												<div class="conNam">234</div>
												<div class="num">(10)</div>
											</div>
											<div class="mesList">
												<div class="conNam">345</div>
												<div class="num">(10)</div>
											</div>
											<div class="mesList">
												<div class="conNam">456</div>
												<div class="num">(10)</div>
											</div>
										</div>
									</div>
								</el-dropdown-menu>
							</el-dropdown> -->
              <!-- 主题管理 -->
              <!-- 			<el-dropdown>
								<div class="bkcolor item el-dropdown-link" title="更换主题颜色"><i class="el-icon-brush"></i></div>
								<el-dropdown-menu slot="dropdown">
									<div class="colorStyle">
										<div class='color' @click="styleColor('#1ABC9C')" style="background-color: #1ABC9C;">
											点击换肤
										</div>
										<div class='color' @click="styleColor('#3498DB')" style="background-color: #3498DB;">
											点击换肤
										</div>
										<div class='color' @click="styleColor('#ED6E4D')" style="background-color: #ED6E4D;">
											点击换肤
										</div>
										<div class='color' @click="styleColor('#FABB3D')" style="background-color: #FABB3D;">
											点击换肤
										</div>
										<div class='color' @click="styleColor('#AA7AB3')" style="background-color: #AA7AB3;">
											点击换肤
										</div>
										<div class='color' @click="styleColor('#9AD0B9')" style="background-color: #9AD0B9;">
											点击换肤
										</div>
										<div class='color' @click="styleColor('#EA94BE')" style="background-color: #EA94BE;">
											点击换肤
										</div>
										<div class='color' @click="styleColor('#D9001B')" style="background-color: #D9001B;">
											点击换肤
										</div>
									</div>
								</el-dropdown-menu>
							</el-dropdown> -->

              <!-- 退出登录 -->
              <div class="exit item" title="注销登录" @click="loginout()">
                <i class="el-icon-switch-button"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Header>
    <Layout :style="{ marginLeft: '200px' }">
      <Content :style="{ padding: '0 16px 16px' }">
        <Breadcrumb :style="{ margin: '16px 0' }"> </Breadcrumb>
        <Card>
          <div style="height: ">
            <div class="retract">
              <!-- <div class="title">系统首页</div> -->
              <div class="content">
                <!-- 	<div class="content_tit">
									<div class="item"></div>
									<div class="item"></div>
									<div class="item"></div>
									<div class="item back"></div>
								</div> -->
                <!-- 	<div class="content_entrance">
									<div class="entrance">
										<div class="entrance_tit">待处理事务</div>
										<div class="backs_t">
											<div class="backs_mok">
												<div class='backs_name'>待处理事物</div>
												<div class='backs_num'>(0)</div>
											</div>
											<div class="backs_mok">
												<div class='backs_name'>带和小商品</div>
												<div class='backs_num'>(0)</div>
											</div>
											<div class="backs_mok">
												<div class='backs_name'>租金即将到期店铺</div>
												<div class='backs_num'>(0)</div>
											</div>
										</div>
									</div>
								</div> -->
                <div class="content_entrance">
                  <div class="entrance">
                    <div class="entrance_tit">运营快速入口</div>
                    <div class="entrance_cont">
                      <div class="font" @click="addstore">
                        <!-- <i class="el-icon-s-shop" style="color: red"></i> -->
                        <img style="margin-top: 15px;" src="../../../public/index_icon1.png" alt="">
                        <div class="iconText" style="color: black">
                          添加店铺
                        </div>
                      </div>
                      <div class="font" @click="addshop">
                        <!-- <i
                          class="el-icon-first-aid-kit"
                          style="color: black"
                        ></i> -->
                        <img style="margin-top: 15px;" src="../../../public/index_icon2.png" alt="">
                        <div class="iconText" style="color: black">
                          添加商品
                        </div>
                      </div>
                      <div class="font" @click="order">
                        <!-- <i class="el-icon-document" style="color: red"></i> -->
                        <img style="margin-top: 15px;" src="../../../public/index_icon3.png" alt="">
                        <div class="iconText" style="color: black">
                          订单中心
                        </div>
                      </div>
                      <div class="font" @click="member">
                        <!-- <i class="el-icon-s-custom" style="color: red"></i> -->
                        <img style="margin-top: 15px;" src="../../../public/index_icon4.png" alt="">
                        <div class="iconText" style="color: black">
                          会员管理
                        </div>
                      </div>
                      <!-- 	<div class="font">
												<i class="el-icon-s-marketing"></i>
												<div class="iconText">交易统计</div>
											</div>
											<div class="font">
												<i class="el-icon-chat-dot-round"></i>
												<div class="iconText">短息营销</div>
											</div> -->
                      <div class="font" @click="banner">
                        <!-- <i class="el-icon-s-platform" style="color: red"></i> -->
                        <img style="margin-top: 15px;" src="../../../public/index_icon5.png" alt="">
                        <div class="iconText" style="color: black">
                          banner管理
                        </div>
                      </div>
                      <!-- 		<div class="font">
												<i class="el-icon-box"></i>
												<div class="iconText">团购管理</div>
											</div> -->
                      <div class="font" @click="miao">
                        <!-- <i class="el-icon-alarm-clock" style="color: red"></i> -->
                        <img style="margin-top: 15px;" src="../../../public/index_icon5.png" alt="">
                        <div class="iconText" style="color: black">
                          秒杀管理
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="content_entrance">
                  <div class="double">
                    <zong></zong>
                  </div>
                </div>

                <!-- <div class="month-data-container"> -->
                <!-- <div ref="echartsContainer" class="echartsContainer"></div> -->
                <!-- <div ref="echartsContainerTwo" class="echartsContainer"></div> -->
                <!-- </div> -->

                <!-- <div class="content_entrance" v-if="login_type==1||login_type==2">
									<div class="entrance">
										<div class="entrance_tit">平台销售前八商户 -->
                <div class="table">
                  <div ref="chartbox"></div>
                </div>
                <div class="table">
                  <div style="margin-right: 80%; font-size: 20px; margin-top: 50px">
                    平台销售前八商户
                  </div>
                  <el-table :data="datas" border style="
                      width: 96%;
                      margin-left: 2%;
                      text-align: center;
                      margin-top: 20px;
                    ">
                    <!-- 	<el-table-column prop="pai" label="排行" align="center">
										  </el-table-column> -->
                    <el-table-column prop="s_name" label="门店名称" align="center">
                    </el-table-column>
                    <el-table-column prop="s_logo" label="门店图片" align="center">
                      <template slot-scope="scope">
                        <img v-bind:src="imgUrl + scope.row.s_logo" style="width: 100px; height: 100px" />
                      </template>
                    </el-table-column>

                    <el-table-column prop="tel" label="联系电话" align="center">
                    </el-table-column>
                    <el-table-column prop="user" label="门店负责人" align="center">
                    </el-table-column>
                    <el-table-column prop="ctime" label="创建时间" align="center">
                    </el-table-column>
                  </el-table>
                </div>
                <div class="table">
                  <div style="margin-right: 80%; font-size: 20px; margin-top: 50px">
                    热销商品前八
                  </div>
                  <el-table :data="shopList" border style="
                      width: 96%;
                      margin-left: 2%;
                      text-align: center;
                      margin-top: 20px;
                    ">
                    <el-table-column prop="gname" label="商品名称" align="center">
                    </el-table-column>
                    <el-table-column prop="gimg" label="商品图片" align="center">
                      <template slot-scope="scope">
                        <img v-bind:src="imgUrl + scope.row.gimg" style="width: 100px; height: 100px" />
                      </template>
                    </el-table-column>

                    <el-table-column prop="gtitle" label="商品简称" align="center">
                    </el-table-column>
                    <el-table-column prop="c_name" label="所属分类" align="center">
                    </el-table-column>
                    <el-table-column prop="selling_price" label="售价" align="center">
                    </el-table-column>
                  </el-table>
                </div>

                <!-- 	</div>
										<div class="backs_t">
										
										</div>
									</div>
								</div> -->
                <!-- <div class="content_entrance">
									<div class="entrance">
										<div class="entrance_tit">热销商品前八</div>
										<div class="backs_t">
										
										</div>
									</div>
								</div> -->
              </div>
            </div>
            <div>{{ txt }}</div>
          </div>
        </Card>
      </Content>
    </Layout>
  </div>
</template>

<script>
import zong from "./Zong.vue";
import echarts from "echarts"; //引入echarts
export default {
  name: "frame",
  data() {
    return {
      login_name: "", //登陆名字
      selList: [],
      login_type: "",
      datas: [],
      shopList: [],
      imgU: "",
      imgUrl: "",
      pai: [1, 2, 3, 4, 5, 6, 7, 8],
      txt: "",
    };
  },
  components: {
    zong,
  },
  props: {
    top1: {
      type: String,
    },
    top2: {
      type: Array,
    },
  },
  created() {
    let imgU = this.$request.readimgUrl();
    this.imgU = imgU;
    let login_type = this.$storage.getLocal("type");
    this.login_type = login_type;
    let token = this.$storage.getLocal("token");
    this.login_name = this.$storage.getLocal("login_name");
    this.$nextTick(() => {
      this.$refs.titleUser.style.backgroundColor =
        this.$storage.getLocal("color");
    });
    this.$request.selRoleList({ token }).then((res) => {
      if (res.code == 0) {
        this.selList = res.data;
        this.$storage.setLocal(res.data, "selList");
      }
    });
    this.imgUrl = this.$request.imgUrl2() + "uploads/";
    // var admin_type=this.$storage.getLocal('type')
    // this.admin_type=admin_type
    // let token = this.$storage.getLocal('token');
    let limit = 8;
    let page = 1;
    let s_name = "";
    this.$request
      .storeList({ token, page, limit, s_name, value: this.value })
      .then((res) => {
        if (res.code == 0) {
          this.datas = res.data;
        }
      });

    this.$request
      .goodsQueryList({
        token,
        gname: "",
        storeid: "",
        pstoreid: "",
        page: 1,
        limit: 8,
        status: 1,
      })
      .then((res) => {
        if (res.code == 0) {
          this.shopList = res.data.data;
          // this.num = res.data.cunt
        }
      });
  },
  mounted() {
    // 初始化执行
    this.user_draw();
  },
  methods: {
    draw_pic_one(arg) {
      let dayDom = this.$refs.echartsContainer;
      let myChart = this.$echarts.init(dayDom); //初始化图表
      myChart.setOption(arg);
    },

    //又一个图
    draw_pic_two(arg) {
      let dayDom = this.$refs.echartsContainerTwo;
      let myChart = this.$echarts.init(dayDom);
      myChart.setOption(arg);
    },

    user_draw() {
      let option2 = {
        color: ["#ecac99"],

        tooltip: {
          //是否显示提示框组件，包括提示框浮层和 axisPointer。
          trigger: "axis", //axis 坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用。
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        title: {
          //图形title
          left: "center",
          text: "",
        },
        xAxis: {
          //直角坐标系 grid 中的 x 轴
          type: "category", //category 适用于离散的类目数据
          data: [
            "2018/11/11",
            "2018/11/12",
            "2018/11/13",
            "2018/11/14",
            "2018/11/15",
            "2018/11/16",
            "2018/11/17",
            "2018/11/18",
            "2018/11/19",
            "2018/11/20",
            "2018/11/21",
            "2018/11/22",
            "2018/11/23",
            "2018/11/24",
            "2018/11/25",
            "2018/11/26",
            "2018/11/27",
            "2018/11/28",
            "2018/11/29",
          ],
        },
        grid: {
          // 屏幕位置
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true, //是否包含坐标轴的刻度标签 true为显示
          borderWidth: 1, //网格的边框线宽。
        },

        yAxis: [
          {
            type: "value",
            name: "睡眠时长",
            nameLocation: "center", //坐标轴名称显示位置。
            nameGap: 30, //与轴线间距
          },
        ],
        dataZoom: [
          {
            //区域缩放，从而能自由关注细节的数据信息
            type: "inside",
            start: 0,
            end: 100,
          },
          {
            start: 0,
            end: 100,
            handleIcon:
              "M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
            handleSize: "80%",
            handleStyle: {
              color: "#fff",
              shadowBlur: 3,
              shadowColor: "rgba(0, 0, 0, 0.6)",
              shadowOffsetX: 2,
              shadowOffsetY: 2,
            },
          },
        ],
        series: [
          //每个系列通过 type 决定自己的图表类型
          {
            name: "",
            type: "line",
            smooth: false,
            symbol: "none",
            sampling: "average",
            itemStyle: {
              color: "rgb(244, 206, 174)",
            },
            areaStyle: {
              color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgb(244, 206, 174)",
                },
                {
                  offset: 1,
                  color: "rgb(244, 206, 174)",
                },
              ]),
            },
            data: [
              100, 120, 112, 113, 78, 79, 89, 80, 98, 86, 65, 87, 90, 99, 88,
              77, 123, 112, 113,
            ],
          },
        ],
      };
      //给上面两个图设置参数
      this.draw_pic_one(option2);
      this.draw_pic_two(option2);
    },

    skip(url, txt) {
      this.txt = txt;
      this.$router.push(
        { path: "/" + url },
        function () { },
        function () { }
      );
    },
    styleColor(obj) {
      this.$storage.setLocal(obj, "color");
      this.$refs.titleUser.style.backgroundColor =
        this.$storage.getLocal("color");
    },
    loginout() {
      let token = this.$storage.getLocal("token");
      this.$request.logout({ token }).then((res) => {
        if (res.code == 0) {
          localStorage.clear();
          this.$router.push(
            { path: "/login" },
            function () { },
            function () { }
          );
        }
      });
    },
    addstore() {
      this.$router.push({ path: "addList" });
    },
    addshop() {
      this.$router.push({ path: "addCommodity" });
    },
    order() {
      this.$router.push({ path: "orderList" });
    },

    member() {
      this.$router.push({ path: "member" });
    },
    banner() {
      this.$router.push({ path: "advertisingList" });
    },
    miao() {
      this.$router.push({ path: "activityList" });
    },
  },
};
</script>


<style scoped="scoped">
.echartsContainer {
  width: 100%;
  height: 300px;
}

.menu {
  position: fixed;
  z-index: 1999;
  width: 100%;
}

.ivu-layout-sider {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  flex: 0 0 200px;
  position: fixed;
  height: 93vh;
  top: 65px;
  left: 0px;
  overflow: auto;
}

.layout {
  border: 1px solid #d7dde4;
  background: #f5f7f9;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}

.layout-header-bar {
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}

/* 推荐菜单 */
.menus {
  width: 400px;
  height: 300px;
}

.menuCon {
  width: 88%;
  margin: 0 auto;
}

.menuTit {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  color: #8492a6;
  margin-top: 10px;
}

.menuNei {
  font-size: 15px;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #333333;
  margin-top: 10px;
}

.menuList {
  width: 33%;
  height: 90px;
  text-align: center;
  line-height: 90px;
}

/* 信息 */
.messages {
  width: 400px;
  height: 300px;
  font-size: 15px;
}

.messageCla {
  width: 88%;
  margin: 0 auto;
}

.mesClaTit {
  color: #b4b4b4;
  height: 35px;
}

.mesList {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #cbcbcb;
  height: 35px;
  line-height: 35px;
}

/* 主题颜色 */
.color {
  width: 24.5%;
  height: 80px;
  border: 1px solid #ffffff;
  background: #333333;
  text-align: center;
  line-height: 80px;
  font-size: 12px;
  color: #ffffff;
}

.colorStyle {
  width: 400px;
  height: 160px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 10px;
}

.colorStyle {
  background-color: #ffffff;
}

/* top */
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}

.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}

.loginText {
  color: black;
  font-size: 25px;
  font-weight: 800;
  line-height: 60px;
}

.login img {
  width: 160px;
  height: 45px;
  margin-top: 11px;
}

.login {
  min-width: 200px;
  max-width: 200px;
  background-color: red;
}

.titleUser {
  width: 100%;
  height: 64px;
  line-height: 64px;
  /* background-color: #D9001B; */
  display: flex;
}

.title {
  height: 100%;
}

.titleO {
  width: 87%;
  display: flex;
  /* background-color: white; */
  background-color: white;
  /* color: black; */
}

.title_left {
  width: 50%;
  display: flex;
}

.title_right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.title .item {
  line-height: 60px;
  color: black;
  margin-right: 10px;
  /* font-size: 20px; */
}

.index,
.list,
.message,
.bkcolor,
.exit {
  font-size: 20px;
}

.admin {
  font-size: 20px;
  color: black;
}

/* 	.item
	{
		color: black;
	} */

.message {
  position: relative;
}

.message .quan {
  width: 15px;
  height: 15px;
  background-color: #ff5918;
  position: absolute;
  top: 15px;
  right: -5px;
  border-radius: 15px;
  text-align: center;
  line-height: 15px;
  font-size: 10px;
}

.retract {
  background-color: #f8f8f8;
  width: 100%;
}

.title {
  font-size: 20px;
  text-align: left;
  padding-left: 20px;
  height: 30px;
  line-height: 30px;
}

.content_tit {
  width: 95%;
  height: 150px;
  /* background-color: #333333; */
  /* line-height: 150px; */
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
}

.content_tit .item {
  width: 20%;
  height: 100px;
  background-color: #b3b3b3;
  border-radius: 10px;
  margin: auto 0;
  -moz-box-shadow: 2px 2px 5px #333333, -1px -1px 5px #333333;
  -webkit-box-shadow: 2px 2px 5px #333333, -1px -1px 5px #333333;
  box-shadow: 2px 2px 5px #333333, -1px -1px 5px #333333;
}

.back {
  margin-right: 50px;
}

.content_pending {
  width: 100%;
  /* background-color: #2C3E50; */
  height: 100px;
}

.backs {
  width: 95%;
  height: 100%;
  background-color: #ffffff;
  /* margin: 0 auto; */
  margin-left: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2),
    -1px -1px 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2),
    -1px -1px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), -1px -1px 5px rgba(0, 0, 0, 0.2);
  /* paddin: 20px; */
}

.backs_tit {
  background-color: #f3f3f3;
  height: 30px;
  line-height: 30px;
  text-align: left;
  /* padding-left: 20px; */
  font-size: 20px;
  padding-left: 20px;
  font-weight: 800;
}

.backs_mok {
  font-size: 20px;
}

.backs_t {
  display: flex;
  justify-content: space-between;
  width: 94%;
  height: 70px;
  margin: 0 auto;
}

.backs_mok {
  display: flex;
  width: 30%;
  line-height: 70px;
  justify-content: space-between;
}

.backs_name {
  color: #838383;
}

.content_entrance {
  width: 100%;
  /* height: 500px; */
  margin-top: 50px;
}

.entrance {
  width: 96%;
  height: 100%;
  margin: 0 auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  /* padding-left: 20px; */
  /* background-color: #2C3E50; */
  /* -moz-box-shadow:2px 2px 5px rgba(0,0,0,0.2), -1px -1px 5px rgba(0,0,0,0.2); */
  /* -webkit-box-shadow:2px 2px 5px rgba(0,0,0,0.2), -1px -1px 5px rgba(0,0,0,0.2); */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), -1px -1px 5px rgba(0, 0, 0, 0.2);
}

.entrance_tit {
  background-color: #f3f3f3;
  height: 50px;
  line-height: 30px;
  text-align: left;
  /* padding-left: 20px; */
  font-size: 20px;
  padding-left: 20px;
  font-weight: 800;
}

.entrance_cont {
  display: flex;
  height: 150px;
}

.font {
  height: 100%;
  width: 17%;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: #a3a3a3;
}

.iconText {
  font-size: 15px;
}

.font:hover {
  background-color: #f3f3f3;
  border: 1px solid #cbcbcb;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), -1px -1px 5px rgba(0, 0, 0, 0.2);
  /* color: #42B983; */
}

.font i {
  width: 100%;
  height: 60%;
  font-size: 60px;
  line-height: 90px;
}

.img1 {
  width: 15px;
  height: 15px;
  margin-top: 5px;
}
</style>
