<template>
	<div class="index">
		<indexs></indexs>
	</div>
</template>

<script>
	import indexs from '../../components/index/Index.vue';
	export default{
		name:'index',
		data(){
			return {
				
			}
		},
		components:{
			indexs
		}
	}
</script>

<style scoped="scoped">
</style>
