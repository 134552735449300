<template>
	<div class="zong">
		<div class="text_tit" v-if="login_type==1">
		<div class="text_one" >
			<div class="text_title">
				平台总览
			</div>
			<div class="text_cont">
				<div class="text_o">
					<div class="text_num">{{labelList.room.goodsCount}}</div>
					<div class="text_fl">商品数</div>
				</div>
				<div class="text_o">
					<div class="text_num">{{labelList.room.goodsDownCount}}</div>
					<div class="text_fl">商户数</div>
				</div>
				<div class="text_o">
					<div class="text_num">{{labelList.room.goodsTypeCount}}</div>
					<div class="text_fl">待审核商品</div>
				</div>
			</div>
		</div>
		<div class="text_tow">
			<div class="text_title">
				数据总览
			</div>
			<div class="text_cont">
				<div class="text_o">
					<div class="text_num">{{labelList.order.orderCount}}</div>
					<div class="text_fl">会员总数</div>
				</div>
				<div class="text_o">
					<div class="text_num">{{labelList.order.orderGoodsCount}}</div>
					<div class="text_fl">订单总数</div>
				</div>
				<div class="text_o">
					<div class="text_num">{{labelList.order.orderRefundCount}}</div>
					<div class="text_fl">退款订单总数</div>
				</div>
			</div>
		</div>
			
		</div>
		<div class="text_tit" v-if="login_type==2">
			<div class="text_one" >
				<div class="text_title">
					平台总览
				</div>
				<div class="text_cont">
					<div class="text_o">
						<div class="text_num">{{labelList.room.goodsCount}}</div>
						<div class="text_fl">商品数</div>
					</div>
					<div class="text_o">
						<div class="text_num">{{labelList.room.goodsDownCount}}</div>
						<div class="text_fl">门店数</div>
					</div>
					<div class="text_o">
						<div class="text_num">{{labelList.room.goodsTypeCount}}</div>
						<div class="text_fl">待审核商品</div>
					</div>
				</div>
			</div>
			<div class="text_tow">
				<div class="text_title">
					数据总览
				</div>
				<div class="text_cont">
					<div class="text_o">
						<div class="text_num">{{labelList.order.orderCount}}</div>
						<div class="text_fl">平台会员总数</div>
					</div>
					<div class="text_o">
						<div class="text_num">{{labelList.order.orderGoodsCount}}</div>
						<div class="text_fl">平台订单总数</div>
					</div>
					<div class="text_o">
						<div class="text_num">{{labelList.order.orderRefundCount}}</div>
						<div class="text_fl">平台退款订单总数</div>
					</div>
				</div>
			</div>
			
		</div>
		<div class="text_tit" v-if="login_type==3">
			<div class="text_one" >
				<div class="text_title">
					平台总览
				</div>
				<div class="text_cont">
					<div class="text_o">
						<div class="text_num">{{labelList.room.goodsCount}}</div>
						<div class="text_fl">商品数</div>
					</div>
					<div class="text_o">
						<div class="text_num">{{labelList.room.goodsTypeCount}}</div>
						<div class="text_fl">已上架商品</div>
					</div>
					<div class="text_o">
						<div class="text_num">{{labelList.room.goodsDownCount}}</div>
						<div class="text_fl">已下架商品</div>
					</div>
		
				</div>
			</div>
			<div class="text_tow">
				<div class="text_title">
					数据总览
				</div>
				<div class="text_cont">
					<div class="text_o">
						<div class="text_num">{{labelList.order.orderCount}}</div>
						<div class="text_fl">门店订单总数</div>
					</div>
					<div class="text_o">
						<div class="text_num">{{labelList.order.orderGoodsCount}}</div>
						<div class="text_fl">待发货数</div>
					</div>
					<div class="text_o">
						<div class="text_num">{{labelList.order.orderRefundCount}}</div>
						<div class="text_fl">门店退款订单总数</div>
					</div>
				
				</div>
			</div>
			
		</div>
	</div>
	<!-- <div></div> -->
</template>

<script>
	export default{
		name:'zong',
		data(){
			return {
				login_type:'',//登陆名字
			// selList:[]
			labelList:'',
			}
		},
		created(){
		let token = this.$storage.getLocal("token");
		 let login_type = this.$storage.getLocal("type");
		 this.login_type=login_type
		 this.$request.IndexDataOverview({token,login_type:login_type}).then(r=>{
		 	// if(r.code == 0){ 
		 		this.labelList=r.data
		 	// }
		 })
		 
		 
		}
	}
	
</script>

<style scoped="scoped">
	.text_title{
		text-align: left;
		padding-left: 20px;
		font-weight: 800;
	}
	.text_cont{
		background-color: #FFFFFF;
		display: flex;
		justify-content: space-around;
		
	}
	.text_fl{
		font-size: 14px;
	}
	.text_num{
		color: red;
		font-size: 30px;
	}
	.text_o{
		line-height: 60px;
	}
	.zong{
		width: 96%;
		/* height: ; */
		margin: 0 auto;
	}
	.text_tit{
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
	.text_one,.text_tow{
		width: 48%;
		font-size: 20px;
		/* height: 150px; */
		border: 1px solid rgba(0,0,0,0.2);
		box-shadow:2px 2px 5px rgba(0,0,0,0.2), -1px -1px 5px rgba(0,0,0,0.2);
	}
</style>
